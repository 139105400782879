import React from 'react';
import { Link } from 'gatsby';
import './manutencao.scss';

const SessaoManutencao = () => {
    
    const infoManutencao = {
        titulo: 'hidráulicas e industriais',
        describe: 'Para solucionar o seu problema, nós somos especializados em manutenção industrial, com foco na prevenção e manutenção dos componentes da sua máquina.',
        subtitulo: 'Manutenção',
        altPadrao: 'Companhia da Vedação',
    }

    return (
        <section className='manutencao-wrapper'>
            <div className='manutencao-info-wrapper'>
                <div className='manutencao-text-wrapper'>
                    <h5>{`${infoManutencao.subtitulo}`}</h5>
                    <h3>Manutenções<br/>{`${infoManutencao.titulo}`}</h3>
                    <p>{`${infoManutencao.describe}`}</p>
                    <div className='manutencao-btn-wrap'>
                        <Link to="/Manutencao" alt={`${infoManutencao.altPadrao}`}>
                            Serviços de Manutenção
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SessaoManutencao;