import * as React from "react"
import '../global.scss';

        // ROTAS 
import Head from '../Components/seo/Seo'
import Cabecalho from "../Components/header/Header"
import HomeBanner from "../Components/BannerHome/HomeBanner";
import BannerInfoOne from "../Components/BannerInfo01/BannerOne";
import SessaoManutencao from "../Components/Manutencao/Manutencao";
import Product from "../Components/Produtos/Produtos";
import Processos from "../Components/Processos/logistica";
import Footer from "../Components/footer/Rodape";

const IndexPage = () => {

  const infoIndex = {
    tituloNav: 'Companhia da Vedação',
    describe: 'especializada em produtos para Vedação Hidráulica e serviços de manutenção industrial em Curitiba',
    TituloProcessos: 'Precisando de ajuda com a sua máquina? Mantenha ela sempre em dia!',
    descricaoProcessos: 'Analisamos o seu problema para entregar a melhor solução, produtos de qualidade e  agilidade e excelência na execução.',
  }
  
  return (
    <>
      <Head Titulo={`${infoIndex.tituloNav}`} Descricao={`${infoIndex.describe}`}/>
      <Cabecalho LinkName={'Home'}/>
      <HomeBanner/>
      <BannerInfoOne/>
      <SessaoManutencao/>
      <Product/>
      <Processos Titulo={`${infoIndex.TituloProcessos}`} Describe={`${infoIndex.descricaoProcessos}`}/>
      <Footer/>
    </>
  )
}

export default IndexPage;