import React from 'react';
import { Link } from 'gatsby';
import './produtos.scss';

import Slider from '../Slider/Swiper';

const Product = () => {
    
    const InfoProduct = {
        titulo: 'Produtos para',
        subtitulo: 'Produtos',
        describeUm: 'Imprevistos acontecem e estamos aqui para te ajudar nesses momentos.',
        describeDois: 'Com produtos de qualidade, garantimos a durabilidade das nossas peças disponíveis em estoque.',
        AltPadrao: 'Companhia da vedação - Especializada em produtos para Vedação Hidráulica e serviços de manutenção industrial em Curitiba',
    }
    return (
        <>
            <section className='produtos-wrapper'>
                <div className='produtos-text-position'>
                    <div className='produtos-text-wrapper'>
                        <h5>{`${InfoProduct.subtitulo}`}</h5>
                        <h3>{`${InfoProduct.titulo}`} <br/> reposição e reparos</h3>
                        <p>{`${InfoProduct.describeUm}`}</p>
                        <p>{`${InfoProduct.describeDois}`}</p>
                    </div>
                </div>
                <Slider/>
            </section>
            <div className='produtos-btn-wrapper'>
                <Link to="/Produtos" alt={`${InfoProduct.AltPadrao}`}>
                    Ver mais produtos
                </Link>
            </div>
        </>
    )
}

export default Product;