import React from 'react';
import './bannerOne.scss';

const BannerInfoOne = () => {
    const infoBannerOne = {
        Titulo: 'Evite problemas no futuro',
        Subtitulo: 'Reparos',
        Describe: 'Entre os principais danos ao equipamento, estão a perda de força transmitida, a redução na saída da bomba, a perda da lubrificação, o aumento da temperatura de operação, a contaminação por limalha e a contaminação pela água. Quando isso ocorre, acarreta em problemas de enfraquecimento das propriedades de isolação de um fluído e o comprometimento no funcionamento dos componentes, sem esquecer do uso incorreto de fluídos de baixa qualidade.',
    }
    return (
        <main className='bannerOne-wrapper'>
            <div className='bannerOne-text-wrap'>
                <h5>{`${infoBannerOne.Subtitulo}`}</h5>
                <h3>{`${infoBannerOne.Titulo}`}</h3>
                <p>{`${infoBannerOne.Describe}`}</p>
            </div>
        </main>
    )
}

export default BannerInfoOne;