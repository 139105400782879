import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './bannerHome.scss';


const HomeBanner = () => {

    return (
        <section className='Banner-wrapper'>
            <div className='banner-info-wrap'>
                <div className='banner-text-wrap'>
                    <h5>Especialistas em vedação</h5>
                    <h2>Tudo o que você precisa com a precisão que você procura!</h2>
                </div>
            </div>
            <div className='banner-image-wrap'>
                <div className='banner-image-envelope'>
                    <StaticImage className='Banner-image' src="../../images/bg-home.jpg" placeholder='blurred' loading='lazy' alt="Companhia da Vedação"/>
                </div>
            </div>
        </section>
    )
}

export default HomeBanner;